<script setup lang="ts">
import {computed, ref} from 'vue';
import {useStore} from "vuex";
import Flicking from '@egjs/vue3-flicking';
import { Arrow, Pagination } from '@egjs/flicking-plugins';
import '@egjs/vue3-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';
import '@egjs/flicking-plugins/dist/pagination.css';

const sliderOptions = {
    align: 'center',
    circular: true,
    panelsPerView: -1,
    defaultIndex: 1,
};

const store = useStore();

const posts = computed(() => store.state?.options?.posts);

const plugins = [new Pagination({ type: 'bullet' }), new Arrow()];

</script>

<template>

    <section
        v-if="posts?.length"
        class="relative z-20 news-section md:pt-12 pt-9 md:pb-14 pb-9 bg-turquoise-200 overflow-hidden"
    >
        <h2
            class="text-center xl:text-60 lg:text-48 md:text-30 text-24 font-gill font-bold xl:mb-16 md:mb-8.5 mb-6 px-5"
        >
            Новости
        </h2>
        <div class="max-w-container mx-auto">
            <Flicking :options="sliderOptions" :plugins="plugins">
              <a :href="`/post/${slide.slug}`" v-for="(slide, idx) in posts"
                    :key="idx"
                    class=""
                    draggable="false"
              >
                <article
                    class="bg-white h-full overflow-hidden lg:rounded-t-30 rounded-t-20 xl:rounded-b-60 rounded-b-30 relative xl:w-[426px] lg:w-[250px] w-[222px] lg:mr-8 md:mr-4 mr-4.5 shadow-[0_5px_8px_1px_rgba(156,171,171,.3)] group hover:scale-[1.05] transition-all duration-300"
                    
                >
                    <a :href="slide.url" class="aspect-square w-full">
                        <figure
                            class=" aspect-square w-full overflow-hidden relative z-10"
                        >
                            <img
                                :src="`${slide.image}`"
                                alt="img"
                                class="w-full h-full object-center object-cover transition-all duration-300 "
                                draggable="false"
                            />
                        </figure>
                    </a>
                    <div
                        class="xl:h-[200px] md:h-[140px] py-5.5 xl:px-8 px-4 relative z-10 flex flex-col justify-between"
                    >
                        <a
                            :href="`/post/${slide.slug}`"
                            class="mb-6 xl:text-22 md:text-18 leading-1"
                        >
                            {{ slide.name }}
                        </a>
                        <div class="flex items-center lg:gap-x-5.5 gap-x-2">

                            <template v-if="slide.tags?.length">
                                <a
                                    v-for="tag in slide.tags"
                                    :href="'/' + tag.slug"
                                    class="text-white bg-blue border border-blue transition-all duration-300 block w-fit lg:text-16 text-14 px-3 py-1.5 rounded-full hover:text-blue hover:bg-transparent"
                                    v-html="tag.name"
                                />
                            </template>

                            <p class="xl:text-18 lg:text-16 text-14" v-html="slide.date"></p>
                        </div>
                    </div>
                </article>
              </a>
                <template #viewport>
                    <div
                        class="relative flex items-center md:justify-center justify-between md:gap-x-12 gap-x-5 md:mt-12 mt-8 px-5"
                    >
                        <span class="flicking-arrow-prev flicking-arrow is-thin">
                            <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                class="md:w-auto h-auto w-1.5"
                            >
                                <path
                                    d="M0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1L1 9H2L2 7Z"
                                />
                            </svg>
                        </span>
                        <div class="flicking-pagination"></div>
                        <span class="flicking-arrow-next flicking-arrow is-thin">
                            <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                class="md:w-auto h-auto w-1.5"
                            >
                                <path
                                    d="M8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L2.34315 15.0711C1.95262 15.4616 1.31946 15.4616 0.928932 15.0711C0.538408 14.6805 0.538408 14.0474 0.928932 13.6569L6.58579 8L0.928932 2.34315C0.538408 1.95262 0.538408 1.31946 0.928932 0.928932C1.31946 0.538408 1.95262 0.538408 2.34315 0.928932L8.70711 7.29289ZM7 7H8V9H7V7Z"
                                />
                            </svg>
                        </span>
                    </div>
                </template>
            </Flicking>
        </div>
    </section>

</template>

<style scoped lang="scss">
.news-section {
    .flicking-pagination,
    .flicking-arrow {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        transform: unset;
    }

    .flicking-pagination {
        width: fit-content;
    }

    .flicking-arrow {
        background-color: #fff;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        border: 1px solid var(--dark-blue-color);
        transition: all ease 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
            width: 28px;
            height: 28px;
            border: 1px solid var(--turquoise-color-600);
        }

        &:before,
        &:after {
            content: none;
        }

        svg path {
            fill: var(--dark-blue-color);
            transition: all ease 0.3s;
        }

        &:hover {
            background-color: var(--dark-blue-color);

            svg path {
                fill: #fff;
            }
        }
    }

    .flicking-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 28px;

        @media (max-width: 767px) {
            column-gap: 18px;
        }
    }
}
</style>

<style lang="scss">
.news-section {
    .flicking-viewport {
        overflow: visible;
    }

    .flicking-pagination {
        & > span {
            margin: 0;
            padding: 0;
            background-color: var(--turquoise-color-600);
            width: 12px;
            height: 12px;

            @media (max-width: 767px) {
                width: 8px;
                height: 8px;
            }

            &.flicking-pagination-bullet-active {
                background-color: var(--dark-blue-color);
            }
        }
    }
}
</style>
